<template>
  <transition name="fade" appear>
    <div class="home">
      <button class="download" @click="downLoad">下载</button>
      <div class="center f20">{{ title }}</div>
      <div class="center f16">{{ time }}</div>
      <div class="content_teach" v-loading="loading"   element-loading-text="努力加载中"
    element-loading-spinner="el-icon-loading">
        <iframe class="iframe" :src="filterUrl" frameborder="0"></iframe>
      </div>
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  data() {
    return {
      loading:true,
      title: "",
      time: "",
      filterUrl: "",
      syllabusCode: "",
    };
  },
  methods: {
    getSyllabusDetail() {
      var getData = {};
      getData.syllabusCode = this.syllabusCode;
      this.$api.home.getSyllabusDetail(getData).then((res) => {
        console.log( res.data)
        if (res.data.code === 200) {
          this.filterUrl = res.data.data.url;
          this.title = res.data.data.syllabusName;
          this.loading=false
        }
      });
    },
    downLoad() {
      //下载
      // window.location.href = this.filterUrl;
      window.open(this.filterUrl);
    },
  },
  created() {
    this.$store.commit(CHANGE_NAVINDEX, "0");
    this.syllabusCode = this.$route.query.syllabusCode;
    this.getSyllabusDetail();
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  height: 650px;
  position: relative;
  margin-top: 40px;
  padding: 0 50px;
  background-color: white;
  .center {
    text-align: center;
  }
  .f20 {
    font-size: 26px;
    z-index: 0;
    text-align: center;
    font-weight: bold;
  }
  .f16 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
  }
  .download {
    padding: 8px 31px;
    border: 1px solid #e2982e;
    position: absolute;
    right: 129px;
    font-size: 20px;
    float: right;
    z-index: 1;
    margin-right: 10px;
    border-radius: 6px;
    color: #e2982e;
    background-color: white;
  }
  .content_teach {
    background: #f4f4f4;
    height: 100%;
    margin-top: 20px;
    .iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
